import React, { useEffect } from 'react';

import { ReactComponent as Arrow } from '../../assets/simple-arrow.svg';
import { ReactComponent as LogoSm } from '../../../public/icons-vector.svg';

import './Header.scss';

const App = () => {
  const getLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return null;
    }

    if (/android/i.test(userAgent)) {
      return 'https://play.google.com/store/apps/details?id=com.closetheskyoverukraine.twa&hl=en_US&gl=US';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'https://apps.apple.com/ua/app/closetheskyoverukraine/id1624021891';
    }

    return null;
  };

  if (!getLink()) return null;

  return (
    <a href={getLink()} className="headerApp">
      <div className="headerAppIcon">
        <LogoSm />
      </div>
      <div className="headerAppInfo">
        Download App
        <span>Sky Over Ukraine</span>
      </div>
      <Arrow />
    </a>
  );
};

export default App;
