import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import Links from '../Socials/Links';
import useTranslate from '../../hooks/useTranslate';
import useClickOutside from '../../hooks/useClickOutside';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import MobApp from './MobApp';

import { ReactComponent as Logo } from './img/logo.svg';
import { ReactComponent as Light } from './img/light.svg';
import { ReactComponent as Dark } from './img/dark.svg';

import {
  languages,
  availableLanguages,
  mainNavs,
} from './constants';

import './HeaderNavigation.scss';

const HeaderNavigation = ({ handleSwitchTheme }) => {
  const [isShowMenu, setShowMenu] = useState(false);
  const [currentLang, setCurrentLang] = useState('en');
  const [isShowLang, setShowLang] = useState(false);

  const { t, i18n } = useTranslate();
  const { hash, pathname } = useLocation();
  const isMobile = useDeviceInfo();

  const langRef = useRef(null);
  const menuRef = useRef(null);
  useClickOutside(langRef, isShowLang, () => setShowLang(false));

  const handleLang = (lang) => {
    const languagesMenu = document.querySelector('.headerLanguages');
    i18n.changeLanguage(lang)
      .then(data => {
        setCurrentLang(lang);
        setShowLang(false);
        languagesMenu.style.pointerEvents = 'none';
        setTimeout(() => {
          languagesMenu.style.pointerEvents = 'all';
        }, 10);
      });
  };

  useEffect(() => {
    if (i18n.language && availableLanguages.includes(i18n.language.slice(0, 2))) {
      setCurrentLang(i18n.language.slice(0, 2));
    } else {
      setCurrentLang('en');
    }
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [pathname, hash]);

  return (
    <>
      <div
        className={cn('headerMenuBtn', {
          'headerMenuBtnChecked': isShowMenu,
        })}
        onClick={() => {
          setShowMenu(val => !val);
        }}
      >
        <span className="bar bar1"></span>
        <span className="bar bar2"></span>
        <span className="bar bar3"></span>
        <span className="bar bar4"></span>
      </div>
      <Link to="/" className={cn('headerLogo', {
        '--header-hide': false,
      })}>
        <Logo />
      </Link>
      <div className="headerNavLinks">
        {mainNavs.map(nav => (
          <NavLink key={nav.label} to={nav.link} className="headerNavItem">{t(nav.label)}</NavLink>
        ))}
      </div>
      <div className={cn('headerTheme', {
        '--header-hide': isShowMenu,
      })} onClick={() => handleSwitchTheme()}>
        <div className="headerThemeSwitch">
          <Light className="headerThemeLight" />
          <Dark className="headerThemeDark" />
        </div>
      </div>
      <div className="headerLanguagesWrap">
        <div
          className={cn('headerLanguages', {
            'headerLanguagesOpen': isShowLang,
            '--header-hide': isShowMenu,
          })}
          onClick={() => setShowLang(val => !val)}
          ref={langRef}
        >
          <div className="--current-lang">{languages.find(l => l.symbol === currentLang).label}</div>
          <div className="headerLanguagesOptions">
            {[...languages]
              .sort(lang => lang.symbol === currentLang ? -1 : 1)
              .map(lang => (
                <div
                  key={lang.symbol}
                  onClick={() => handleLang(lang.symbol)}
                >
                  {lang.full}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={cn('headerMenu', {
        'headerMenuOpen': isShowMenu,
      })} ref={menuRef}>
        {mainNavs.map(nav => (
          <NavLink key={nav.label} to={nav.link} className="headerNavItem">{t(nav.label)}</NavLink>
        ))}
        <div className="headerNavPolicies">
          <Link to="/cookies-policy">Cookie Policy</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </div>
        <Links isHeader={true} className="headerNavSocial headerNavSocialMenu" />
        {isMobile && <MobApp />}
      </div>
    </>
  );
};

export default HeaderNavigation;
